//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBqbSAezK_FyQbHtQiKXRjUwdcCd9ewzlI",
  authDomain: "beeshouse.firebaseapp.com",
  projectId: "beeshouse",
  storageBucket: "beeshouse.firebasestorage.app",
  messagingSenderId: "105318176245",
  appId: "1:105318176245:web:4271accd7faf3a2079bcbc",
  measurementId: "G-TMW82EDSSR"
};
//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
